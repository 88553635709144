@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-size: 300;
  font-family: "source sans pro light";
  src: url("assets/fonts/sanspro/SourceSansPro-Light.ttf");
}

@font-face {
  font-size: 400;
  font-family: "source sans pro";
  src: url("assets/fonts/sanspro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-size: 600;
  font-family: "source sans pro semibold";
  src: url("assets/fonts/sanspro/SourceSansPro-SemiBold.ttf");
}

@font-face {
  font-size: 700;
  font-family: "source sans pro bold";
  src: url("assets/fonts/sanspro/SourceSansPro-Bold.ttf");
}

body,
html,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "source sans pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source sans pro", sans-serif;
}

.public-DraftEditor-content h2, .bio-preview h2 {
  font-weight: bold;
}

.bio-preview h2, .bio-preview p {
  line-height: 160%;
  margin: 1rem 0px;
}

#root {
  @apply text-jll-text-base-default;
}

/* Custom Sol Component CSS */
.sol-w-full,
.sol-w-full .sol-button {
  @apply w-full;
}

.sol-bg-transparent .sol-button {
  @apply !bg-transparent;
}

.sol-bg-hover .sol-button {
  @apply !bg-jll-surface-base-secondary-alpha-1 !bg-opacity-10 shadow-none;
}

.sol-dropdown__input-filled {
  @apply !mb-0;
}

/* Tooltip */
.react-tooltip {
  border-radius: 8px !important;
  background: #4c5b62 !important;
  box-shadow: 0px 5px 6px 0px rgba(75, 91, 97, 0.06),
    0px 6px 40px 0px rgba(1, 21, 28, 0.06) !important;
  padding: 16px 20px !important;
  color: white !important;
  opacity: 1;
}

.profile-log table,
.profile-log th,
.profile-log td {
  @apply border-collapse border border-jll-text-base-subdued-3;
}
